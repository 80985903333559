
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    modalController,
    IonButtons,
    IonThumbnail,
    menuController,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiSpese from "@/services/spese";

import ModalNuovaNotaSpesa from "@/components/nota_spesa/ModalNuovaNotaSpesa.vue";
//import RichiestaDetail from "@/components/RichiestaDetail.vue";
import NotaSpesaDetail from "@/components/nota_spesa/NotaSpesaDetail.vue";

export default {
    name: "NotaSpese",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonThumbnail,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).users_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const note_spese = ref([]);

        const openMenu = () => {
            menuController.open("app-menu");
        };

        function setBadgeClass(rimborsata) {
            if (rimborsata == 1) {
                return "badge_success";
            }
        }

        /**
         * Get all nota spese from the server
         */
        async function loadNotaSpese() {
            //console.log("getting richieste from server...");
            loading.value = true;
            try {
                note_spese.value = await apiSpese.getSpese(dipendenteID);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei dati", "toast_danger");
            }
            loading.value = false;
        }

        /**
         * Open modal to create new presenza
         */
        async function openModal() {
            const modal = await modalController.create({
                component: ModalNuovaNotaSpesa,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    note_spese.value = [detail.data, ...note_spese.value];
                    openToast("Nota spesa inserita correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Limit note spesa length to 40 charactes
         */
        function setShortDescription(description) {
            if (!description) {
                return;
            } else {
                const trimmedString = description.length > 45 ? description.substring(0, 42) + "..." : description;
                return trimmedString;
            }
        }

        function setImageUrl(path) {
            return `${process.env.VUE_APP_BASE_URL}/uploads/${path}`;
        }

        /**
         * Passing id to retrive detail in NotaSpesaDetail page
         */
        async function openDetailModal(spesaDetail) {
            //console.log(spesaDetail);
            const modal = await modalController.create({
                component: NotaSpesaDetail,
                componentProps: {
                    data: spesaDetail,
                },
            });
            return modal.present();
        }

        const setStatusColor = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //inserita
                    className = "badge_inserita";
                } else if (statusId == 2) {
                    //accettata
                    className = "badge_accettata";
                } else if (statusId == 3) {
                    //rifiutata
                    className = "badge_rifiutata";
                } else if (statusId == 4) {
                    //rimborsata
                    className = "badge_rimborsata";
                }
                return className;
            };
        });

        onMounted(() => {
            loadNotaSpese();
        });

        return {
            loading,
            dateFormat,
            add,
            refresh,
            openModal,
            //New field
            loadNotaSpese,
            openDetailModal,
            openMenu,
            menu,
            note_spese,
            setBadgeClass,
            setShortDescription,
            setStatusColor,
            setImageUrl,
        };
    },
};
